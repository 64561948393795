<template>
  <div>
    <!-- <p>{{  }}</p> -->
    <vx-card>
      <vs-row>
        <vs-col>
          <vs-divider> توضیحات درخواست </vs-divider>
          <div class="flex">
            <div>
              <span class="my-5 flex font-semibold">
                دسته بندی:
                <p class="text-success mx-2 font-semibold">
                  {{findCategory(demandData[0].service_category_id) }}
                </p>
              </span>
              <span class="my-5 flex font-semibold">
                نام درخواست کننده:
                <router-link :to="`/user?id=${userInfo.id}`" class="underline animate-pulse	text-success mx-2 font-semibold">
                  {{ userInfo.name }}
                </router-link>
              </span>
              <span class="my-5 flex font-semibold">
                وضعیت درخواست:
                <p class="text-success mx-2 font-semibold">
                  {{findStatus(demandData[0].status) }}
                </p>
              </span>
              <div class="w-3/4">
                <div class="my-5 flex font-semibold">
                  توضیحات کاربر پیرامون درخواست:
                </div>
                <p v-html="demandData[0].user_description " class="mx-2">
                </p>
              </div>
            </div>
          </div>
          <vs-divider></vs-divider>
          <div class="my-5">
            <span class="font-semibold"
              >پاسخ ادمین:
              <p v-html="demandData[0].admin_description" class="text-success">
              </p></span
            >
            <span class="font-semibold"
              >قیمت اولیه:
              <p  class="text-success">
                {{ demandData[0].amount || 0 }}
              </p></span
            >
            <span class="font-semibold"
              >قیمت نهایی:
              <p class="text-success">
                {{ demandData[0].final_amount }}
              </p></span
            >
            <span class="font-semibold"
              >دوره پرداخت:
              <div class="flex">
                <p class="text-success">
                  {{ demandData[0].payment_period }}
                </p>
                <p>(ماه)</p>
              </div>
            </span>
          </div>
          <vs-divider> رسیدگی به درخواست </vs-divider>
           <quill-editor v-show="!demandData[0].admin_description"  class="border-solid rounded mb-4" v-model="admin_description" :options="editorOption" />
             
          <!-- <vs-textarea
            v-if="!demandData[0].admin_description"
            class="w-1/2"
            placeholder=" توضیحات ادمین "
            v-model="admin_description"
          ></vs-textarea> -->
          <!-- {{statusArray}} -->
          <vs-select
            class=""
            @change="getAllUser()"
            v-model="status"
            label="status"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in statusArray"
            >
            </vs-select-item>
          </vs-select>
          <div v-if="!demandData[0].admin_description">
            <vs-input
              type="number"
              class="my-1"
              placeholder="قیمت خدمات"
              v-model="amount"
            ></vs-input>
            <vs-input
              class="my-1"
              placeholder="دوره پرداخت"
              type="number"
              v-model="payment_period"
            ></vs-input>
            <div>
              <p class="text-danger w-1/4">
                فیلد را خالی بگذارید اگر دوره پرداختی وجود ندارد.
              </p>
            </div>
            <template> </template>
          </div>
          <vs-button class="my-5" @click="updateDemand()"
            >بروز رسانی درخواست</vs-button
          >
          <vs-divider></vs-divider>
          <!-- <vs-button class="" color="#f00" @click="submitDemand()"
            >تصویه هزینه</vs-button
          > -->
        </vs-col>
        <div style="height: 100px"></div>
      </vs-row>
    </vx-card>
    <vs-table :data="statuslist">
      <template>
        <vs-th></vs-th>
      </template>
      <template> </template>
      <template> </template>
    </vs-table>
  </div>
</template>


<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components : {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link', 'blockquote'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }]
            
          ]
        },
        placeholder: 'توضیحات ادمین....'
      },
      demandData: [],
      userInfo: [],
      USERID: "",
      statuslist: [],
      categorylist: [],
      //====================
      status: 0,
      admin_description: "",
      amount: "",
      payment_period: null,
      statusArray: [],
      //====================
    };
  },
  methods: {
    async getDemandDetail() {
      this.demandData = null;
      this.$http
        .get(`admin/service/${this.$route.params.id}`)
        .then((res) => {
          console.log(res);
          this.demandData = [res.data.params];
          this.getUserInfo(res.data.params.user_id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserInfo(id) {
      this.$http
        .get(`/admin/user/${id}`)
        .then((res) => {
          this.userInfo = res.data.params.data[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    findStatus (id) {
      return this.statuslist[id - 1][id]
    },
    findCategory(id) {
      console.log(this.categorylist)
      return this.categorylist.find(el => el.id == id).name
    },
    // Dynamic lists=============================================================
    getStatusList() {
      this.$http
        .get("admin/service/status/list")
        .then((res) => {
          console.log(res.data.params);
          this.statuslist = res.data.params
          res.data.params.forEach((el) => {
            this.statusArray.push({
              text: String(Object.values(el)),
              value: String(Object.keys(el)),
            });
          });

          // this.statuslist = res.data.params;
          // console.log("statuslist ====================", this.statuslist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCategoryList() {
      this.$http
        .get("admin/service/categories")
        .then((res) => {
          // console.log(res);
          // console.log(...res.data.params);
          this.categorylist = res.data.params;
          // console.log("categorylist *******************", this.categorylist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //Dynamic lists=============================================================
    updateDemand() {
      this.$http
        .put(
          `admin/service/update/${this.$route.params.id}?service_category_id=${
            this.demandData[0].service_category_id
          }&admin_description_html=${
            this.admin_description || this.demandData[0].admin_description
          }&payment_period=${this.payment_period || 0}&status=${
            this.status
          }&amount=${this.amount || this.demandData[0].amount}`
        )
        .then((res) => {
          // console.log(res);
          this.$vs.notify({ text: res.data.message, color: "success" });
          this.$router.push(`/service/${this.$route.params.id}`);
        })
        .catch((err) => {
          console.log(err);
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
        });
      this.getDemandDetail();
    },
  },
  computed: {
    verify(data) {
      if (data && data < 13 && this.data > -1) {
        return true;
      } else {
        return false;
      }
    },
    windowsWidth() {
      return parseInt(window.innerWidth) >= 900;
    },
  },
  created() {
    this.getDemandDetail();
  },
  mounted() {
    this.getCategoryList();
    this.getStatusList();
  },
};
</script>
